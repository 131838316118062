import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Leaflet from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import RTLInput from './RTLInput';
import { Rating, TextField, Modal, Box } from '@mui/material';
import { getComments, newComment } from '../api/routes';
import { FaDirections } from 'react-icons/fa';

let DefaultIcon = Leaflet.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

Leaflet.Marker.prototype.options.icon = DefaultIcon;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: 'fit-content' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'fit-content',
    borderRadius: '5px'
};

export default function ContactUs({ data }) {
    const selectedPosition = new Leaflet.latLng(36.32532, 59.56084)
    
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const [text, setText] = useState()
    const [star, setStar] = useState('');

    const persianTextRegex = /^[. 0 1 2 3 4 5 6 7 8 9 ، آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ\s]+$/

    const textChange = (event) => {
        if (event.target.value === '')
            return setText(event.target.value)
        else if (event.target.value.match(persianTextRegex)) {
            return setText(event.target.value)
        } else return alert('متن پیام باید فارسی باشد!')
    }

    const newComments = () => {
        if (text && text !== '' && star !== '') {
            const data = {
                text,
                rating: star,
            }
            newComment(data, (isOk, data) => {
                if (!isOk) return alert(data.message);

                else {
                    handleClose()
                    setText()
                    setStar('')
                    return alert('نظر شما ثبت شد!')
                }
            })
        } else return alert('تمام مقادیر را پرکنید!')
    }
    const [comments, setComments] = useState([]);

    useEffect(() => {
        getComments((isOk, data) => {
            if (!isOk) return alert(data.message);
            else return setComments(data)
        })
    }, [])
    const options = {
        doubleClickZoom: false,
        closePopupOnClick: false,
        dragging: false,
        zoomSnap: false,
        zoomDelta: false,
        trackResize: false,
        touchZoom: false,
        scrollWheelZoom: false
    }
    return (
        <div className='container pt-[6.5rem]' id='contactUs'>
            <div className='flex justify-around items-center flex-wrap'>
                <section className='pr-3 sm:pr-0 relative'>
                    <p className='text-lg sm:text-2xl text-[#25406e] font-bold'>تماس با ما</p>
                    <div className='w-12 h-1 mt-4 bg-[#3ecddb]' />
                    <section className='flex mt-7 lg:w-[400px] xl:w-[570px]'>
                        <p className='text-base sm:text-lg font-bold ml-3 min-w-fit'>آدرس :</p>
                        <p className='text-sm sm:text-base'>{data ? data.AddressDep : ''}</p>
                    </section>
                    <section className='flex mt-5'>
                        <p className='text-base sm:text-lg font-bold ml-3 min-w-fit'>شماره تماس :</p>
                        <p className='text-sm sm:text-base'>{data ? data.TelDep : ''}</p>
                    </section>
                    <section className='flex mt-5 lg:w-[400px] xl:w-[570px]'>
                        <p className='text-base sm:text-lg font-bold ml-3 min-w-fit'>ساعات کاری :</p>
                        <p className='text-sm sm:text-base'>{data ? data.workingTime : ''}</p>
                    </section>
                    <section className='w-[300px] sm:w-[570px] mx-auto sm:mx-0 lg:w-[400px] xl:w-[570px] h-[223px] mt-8 relative'>
                        <MapContainer {...options} center={selectedPosition} zoom={16} className='w-full h-full -z-30'>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={selectedPosition} />
                        </MapContainer>
                        <a href='https://www.google.com/maps/dir//36.3247584,59.5617387/@36.3245892,59.5606008,18.01z?entry=ttu' target='_blank' rel="noopener noreferrer">
                            <div className='bg-white absolute text-[#8a8b8b] w-[90px] rounded-tl-xl text-sm bottom-0 right-0 h-[85px] flex flex-col items-center justify-center'><FaDirections className='mb-2' size='18px' />مسیر یابی</div>
                        </a>
                    </section>
                </section>
                <div className='w-[95%] mx-auto sm:mx-0 lg:w-[450px] xl:w-[500px] h-[479px] bg-white flex flex-col py-8 px-4 rounded-md shadow mt-8 xl:mt-0'>
                    <section className='w-full h-full flex flex-col overflow-y-scroll'>
                        {comments.map(comment =>
                            <section key={comment.commentId} className='flex items-center justify-between text-xs w-full my-2'>
                                <div className='flex items-center'>
                                    <Rating value={comment.rating} readOnly size="small" />
                                    <p className='mr-2'>{comment.text}</p>
                                </div>
                                <p className='text-[#d2d2d2]'>{`${String(comment.createdAt).slice(0, 4)}/${String(comment.createdAt).slice(4, 6)}/${String(comment.createdAt).slice(6)}`}</p>
                            </section>
                        )}
                    </section>
                    <section style={{ direction: 'ltr' }}>
                        <button onClick={handleOpen} className='bg-[#3ecddb] button-shadow hover:bg-[#fa3668] transition rounded-lg w-[183px] h-[54px] text-white text-sm font-bold mt-11'>ثبت نظر برای دکتر</button>
                    </section>
                </div>
            </div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <RTLInput>
                        <section className='flex flex-col'>
                            <TextField color="primary" multiline rows={5} label="متن" value={text} onChange={textChange} InputProps={{ disableUnderline: true }} variant="filled" className='w-full sm:w-[400px] rounded-[10px] text-sm mt-6' />
                            <Rating className='mt-6' value={star === '' ? 0 : star} onChange={(event, newValue) => setStar(newValue)} />
                            <button onClick={newComments} className='bg-[#3ecddb] button-shadow hover:bg-[#fa3668] transition rounded-lg w-full h-[45px] text-white text-sm font-bold mt-8'>ارسال</button>
                        </section>
                    </RTLInput>
                </Box>
            </Modal>
        </div>
    )
}