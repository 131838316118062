import React from 'react'
import { FaInstagram, FaTelegramPlane } from 'react-icons/fa'
import { BsTelephone } from 'react-icons/bs'
import { Link } from 'react-router-dom';

export default function Footer({ data }) {
    return (
        <footer className='pt-12 pb-6 bg-white'>
            <div className='lg:flex grid md:justify-items-center grid-cols-1 gap-y-4 md:grid-cols-2 md:flex-row flex-col container px-6 md:px-0 md:items-center md:justify-around flex-wrap'>
                <section>
                    {data && <img loading='lazy' src={`data:image/jpeg;base64,${data.logo}`} alt='' className='w-[105px] object-contain' />}
                    <p className='text-sm text-[#898fa7f5] mt-4 w-full sm:w-[300px]'>پزشکی هسته ای یکی از شاخه های علم پزشکی است که به دو قسمت تشخیصی و درمانی تقسیم بندی می شود. با استفاده از دستگاه های پزشکی هسته ای، سلامت یا بیماری سیستم های بدن را بررسی می کنیم و به بررسی عملکرد (فانکشن) ارگانها می پردازیم.</p>
                </section>
                <section>
                    <p className='text-base sm:text-lg font-bold md:mt-0 mt-8'>ارتباط با ما</p>
                    <div className='w-12 h-1 mt-2 bg-[#3ecddb]' />
                    <a href="https://www.instagram.com/sadra.nuclear.medicine/" target='_blank' rel="noopener noreferrer">
                        <p className='text-sm text-[#898fa7f5] mt-4 flex items-center'><FaInstagram className='ml-1' />sadra.nuclear.medicine</p>
                    </a>
                    <a href="#" target='_blank' rel="noopener noreferrer">
                        <p className='text-sm text-[#898fa7f5] mt-2 flex items-center'><FaTelegramPlane className='ml-1' /></p>
                    </a>
                    {data && <a href='tel:05137606010'>
                        <p className='text-sm text-[#898fa7f5] mt-2 flex items-center'><BsTelephone className='ml-1 -scale-x-100' />{data.TelDep}</p>
                    </a>}
                </section>
                <section>
                    <p className='text-base sm:text-lg font-bold md:mt-0 mt-8'>لینک های مفید</p>
                    <div className='w-12 h-1 mt-2 bg-[#3ecddb]' />
                    <p className='text-sm text-[#898fa7f5] mt-4'>نوبت دهی</p>
                    <p className='text-sm text-[#898fa7f5] mt-2'>جواب دهی بیماران</p>
                    <Link to={'/'}>
                        <p className='text-sm text-[#898fa7f5] mt-2'>ورود پزشکان</p>
                    </Link>
                </section>
                <section>
                    <p className='text-base sm:text-lg font-bold md:mt-0 mt-8'>ساعات پذیرش</p>
                    <div className='w-12 h-1 mt-2 bg-[#3ecddb]' />
                    {data && <section className='flex flex-col'><p className='text-sm text-[#898fa7f5] mt-4 w-52'>همه روزه</p>
                        <p className='text-sm text-[#898fa7f5] mt-2'>به غیر از روز‌های تعطیل</p>
                        <p className='text-sm text-[#898fa7f5] mt-2'>9 صبح تا 9 شب</p></section>}
                </section>
            </div>
            <section className='container'>
                <hr className='my-6' />
                <p className='text-center text-[#898fa7f5] text-sm'>ساخته شده با ❤️ در <a href='https://gerafi.com' target='_blank' rel="noopener noreferrer" className='text-[#3ecddb] font-bold'>گرافی دات کام</a></p>
            </section>
        </footer>
    )
}
