import React, { useEffect, useState } from 'react'
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import FrequencyQuestions from '../components/FrequencyQuestions';
import Insurance from '../components/Insurance';
import Nav from '../components/Nav'
import AboutUs from './../components/AboutUs';
import { getDepartmentInfo, getScenario, getSlideShow } from './../api/routes';
import { Backdrop, CircularProgress } from '@mui/material';
import Gallery from '../components/Gallery';
import Services from '../components/Services';
import moment from 'jalali-moment';
import "flickity/css/flickity.css";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home() {
    const [departmentInfo, setDepartmentInfo] = useState();
    const [open, setOpen] = useState(false);
    const [scenarios, setScenarios] = useState([]);
    const [slideShow, setSlideShow] = useState([])

    const m = moment()
    m.locale('fa');

    useEffect(() => {
        getDepartmentInfo((isOk, data) => {
            if (!isOk) {
                return alert(data.message);
            }
            else {
                return setDepartmentInfo(data)
            }
        })
        getSlideShow((isOk, data) => {
            if (!isOk) {
                return alert(data.message);
            }
            else {
                return setSlideShow(data)
            }
        })

        sessionStorage.clear()
    }, [])



    return (
        <>
            <div style={{ background: 'url(/assets/images/headerbg.svg) 0% 57.79% / cover no-repeat', inset: '-40% 0px' }}>
                <header className='container'>
                    <Nav logo={departmentInfo && departmentInfo[0].logo} />
                    <div className='h-[95px]' />
                    <Slider className='w-[80%] mx-auto sm:w-[90%] mt-10 sm:mt-24 sm:mb-10 lg:w-[95%] xl:w-full xl:mx-0' dots={true} rtl slidesToShow={1} infinite={true} slidesToScroll={1} speed={2000} arrows={true}>
                        {slideShow.map(item => <div>
                            <section className='flex items-center justify-around flex-wrap-reverse'>
                                <section className='w-full sm:w-[455px] mt-8 lg:mt-0 text-right'>
                                    <p className='text-[14px] text-justify' style={{ direction: "rtl" }}>{item.text}</p>
                                </section>
                                <img src={`data:image/jpeg;base64,${item.image}`} alt='slideShow' className='w-full sm:w-auto sm:h-[350px] object-contain rounded' />
                            </section>
                        </div>)}
                    </Slider>
                </header>
            </div >
            <AboutUs aboutText={departmentInfo && departmentInfo[0].aboutText} aboutImage={departmentInfo && departmentInfo[0].aboutImage} />
            <Services />
            <Gallery />
            <FrequencyQuestions />
            <ContactUs data={departmentInfo && departmentInfo[0]} />
            <Insurance />
            <Footer data={departmentInfo && departmentInfo[0]} />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
