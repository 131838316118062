import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { getCenterHealthInfo } from "../../api/routes";
import { updateTab } from "../../features/tab/tabSlice";
import setToSession from "./setToSession";

export default function PatientHealthInfo() {
  const dispatch = useDispatch();
  const [healthInfo, setHealthInfo] = useState([]);
  const [clickedHealthInfo, setClickedHealthInfo] = useState({});

  useEffect(() => {
    getCenterHealthInfo((isOk, data) => {
      if (!isOk) return alert(data.message);
      else {
        if (data.length === 0) {
          dispatch(updateTab(4))
        }
        else return setHealthInfo(data);
      }
    })
  }, [])

  const onLinkChange = (event, infoId) => {
    setClickedHealthInfo({
      ...clickedHealthInfo,
      [infoId]: { text: event.target.name, isChecked: event.target.checked },
    });
  }

  const object2arr = Object.keys(clickedHealthInfo).map((key) => ({ id: Number(key), text: clickedHealthInfo[key].text, isChecked: clickedHealthInfo[key].isChecked }));
  const selectedItem = object2arr.filter(item => item.isChecked !== false);

  const nextLevel = () => {
    setToSession("healthInformation", selectedItem, 30);
    return dispatch(updateTab(4))
  }
  useEffect(() => {
    const allSesstionStorage = Object.keys(sessionStorage).map((key) => ({ name: key, value: JSON.parse(sessionStorage[key]).value, expiredAt: JSON.parse(sessionStorage[key]).expiredAt }));
    allSesstionStorage.map(item => {
      let expiredAt = new Date(item.expiredAt);
      if (expiredAt > new Date()) {
        return item.value;
      } else {
        sessionStorage.clear();
        return dispatch(updateTab(0));
      }
    })
  }, [])

  return (
    <>
      <div className='flex flex-wrap justify-between items-center'>
        {healthInfo.length > 0 ? healthInfo.map((item) =>
          <section key={item.healthInfoId} className={'flex items-center mb-4 border rounded-md w-full md:w-[45%] px-1 py-2 mt-2 md:mt-4 mr-4'}>
            <input id="checkbox" type="checkbox" name={item.text} onChange={(event) => onLinkChange(event, item.healthInfoId)} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300" />
            <label htmlFor="checkbox" className="ml-2 text-[13px] pr-2">{item.text}</label>
          </section>
        ) : 'هنوز متنی وارد نشده است!'}
      </div>
      <section className='flex justify-end mt-4'>
        <button type="button" onClick={nextLevel} className='focus:outline-none text-white  bg-[#2ecd71] hover:bg-green-500 focus:ring-green-300 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 w-[120px]'>ادامه</button>
      </section>
    </>
  )
}
