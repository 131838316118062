import { getAxiosInstanceApi, getReportInstanceApi } from "./api";
import { cachiosInstance } from "./cacheApi";

export const getAbout = (callback) => {
    cachiosInstance.get('/site/about')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getFrequencyQuestions = (callback) => {
    cachiosInstance.get('/site/frequencyQuestions')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getSlideShow = (callback) => {
    cachiosInstance.get('/site/slideShow')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getDepartmentInfo = (callback) => {
    cachiosInstance.get('/site/departmentInfo')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getServices = (callback) => {
    cachiosInstance.get('/site/services')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const newComment = (data, callback) => {
    getAxiosInstanceApi().post('/site/comments', data)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getComments = (callback) => {
    cachiosInstance.get('/site/comments')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getRoles = (callback) => {
    cachiosInstance.get('/reservation/rules')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getRequestGroup = (callback) => {
    cachiosInstance.get(`/reservation/requestGroup`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getPatientReport = (receptionCode, callback) => {
    getReportInstanceApi().get(`/getPatientReport/${receptionCode}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getDoctorPatients = (medDoctor, secDoctor, callback) => {
    getReportInstanceApi().get(`/doctorPatients/${medDoctor}/${secDoctor}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getMEDRequestGroup = (callback) => {
    getReportInstanceApi().get('/MEDRequestGroup')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getDoctorInformation = (medicalCouncil, callback) => {
    getReportInstanceApi().get(`/doctorInformation/${medicalCouncil}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const reserveWithOutPayment = (data,callback) => {
    getAxiosInstanceApi().post('/reservation/reserveWithOutPayment',data)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getReportHeader = (callback) => {
    cachiosInstance.get(`/getReportHeader`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getReportSign = (idDoctorCenter, callback) => {
    cachiosInstance.get(`/getReportSign/${idDoctorCenter}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getReservedDays = (selectedMonth, selectedYear, service, subService, callback) => {
    cachiosInstance.get(`/reservation/reservedDays?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&service=${service}&subService=${subService}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getSubRequestGroup = (requestGroupId, callback) => {
    cachiosInstance.get(`/reservation/subRequestGroup/${requestGroupId}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getReservedTimes = (groupId, subGroupId, date, callback) => {
    cachiosInstance.get(`/reservation/reservationTimes?groupId=${groupId}&subGroupId=${subGroupId}&date=${date}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const addReservedSubTimes = (data, callback) => {
    getAxiosInstanceApi().post('/reservation/reservedSubTimes', data)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const addPatientInformation = (data, callback) => {
    getAxiosInstanceApi().post('/patientInformation', data)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getReservedSubTimes = (requestGroupId, reservationDate, subRequestGroupId, callback) => {
    cachiosInstance.get(`/reservation/reservedSubTimes?requestGroupId=${requestGroupId}&reservationDate=${reservationDate}&subRequestGroupId=${subRequestGroupId}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getInsurance = (supplement, callback) => {
    cachiosInstance.get(`/reservation/insurance/${supplement}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getCenterHealthInfo = (callback) => {
    cachiosInstance.get('/reservation/centerHealthInfo')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const addPatientsFiles = (data, callback) => {
    getAxiosInstanceApi().post('/patientsFiles', data)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const addPatientHealthInfo = (formData, callback) => {
    getAxiosInstanceApi().post('/patientHealthInfo', formData)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getDeposit = (paymentCode, callback) => {
    cachiosInstance.get(`/transaction/deposit/${paymentCode}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const addDeposit = (data, callback) => {
    getAxiosInstanceApi().post('/ghaffari/transaction/deposit', data)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const addContactUs = (data, callback) => {
    getAxiosInstanceApi().post('/site/contactUs', data)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getGalleryImage = (callback) => {
    cachiosInstance.get('/site/gallery')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getOneService = (serviceId, callback) => {
    cachiosInstance.get(`/site/services/${serviceId}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getScenario = (todayDate, callback) => {
    cachiosInstance.get(`/reservation/getScenario/${todayDate}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};