import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getDepartmentInfo, getOneService } from '../api/routes';
import Nav from '../components/Nav';
import parse from 'html-react-parser';
import Footer from '../components/Footer';

export default function ServiceSingle() {
    const [departmentInfo, setDepartmentInfo] = useState();
    const [serviceInfo, setServiceInfo] = useState([]);
    const params = useParams();
    const serviceId = params.serviceId;

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getDepartmentInfo((isOk, data) => {
            if (!isOk) return alert(data.message);

            else return setDepartmentInfo(data)
        })
        sessionStorage.clear()
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])
    useEffect(() => {
        handleOpen()
        getOneService(serviceId, (isOk, data) => {
            if (!isOk) {
                handleClose()
                return alert(data.message);
            }
            else {
                handleClose()
                return setServiceInfo(data[0])
            }
        })
    }, [serviceId])

    return (
        <>
            <div className='container'>
                <Nav logo={departmentInfo && departmentInfo[0].logo} />
                <div className='h-[95px]' />
                <main className='px-6 xl:px-0 mt-20 flex flex-col items-center w-full'>
                    <p className='text-2xl text-[#25406e] font-bold'>{serviceInfo.serviceTitle}</p>
                    {serviceInfo && <img loading='lazy' src={`data:image/jpeg;base64,${serviceInfo.image}`} alt='' className='w-full xl:w-1/2 h-full object-cover rounded-[10px] mt-8' />}
                    <div style={{ fontFamily: 'IRANSansX' }} className='my-16 w-full xl:w-1/2 text-sm sm:text-base'>{serviceInfo.serviceText && parse(serviceInfo.serviceText)}</div>
                </main>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            <Footer data={departmentInfo && departmentInfo[0]} />
        </>
    )
}
