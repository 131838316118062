import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reservation from './pages/Reservation';
import ServiceSingle from './pages/ServiceSingle';
import FloatingBtn from './components/FloatingBtn';
import Map from './pages/Map';
import PatientReport from './pages/PatientReport';
import DoctorPatients from './pages/DoctorPatients';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"*"} element={<Navigate to={'/'}/>} />
          <Route path={"/reservation"} element={<Reservation />} />
          <Route path={"/doctor-patients"} element={<DoctorPatients />} />
          <Route path={"/pr"} element={<PatientReport />} />
          <Route path={"/service/:serviceId"} element={<ServiceSingle />} />
          <Route path={"/map"} element={<Map />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={3000} rtl />
      <FloatingBtn />
    </>
  );
}

export default App;
