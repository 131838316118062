import { DataGrid } from '@mui/x-data-grid';
import React from 'react'
import RTLInput from './RTLInput';
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import persian_en from "react-date-object/locales/persian_en"
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Backdrop, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { getMEDRequestGroup } from './../api/routes';
import { useEffect } from 'react';
import moment from 'jalali-moment';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    maxHeight: '100vh',
    overflowY: 'auto',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
};

export default function PatientTable({ data, dateFilter }) {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [clickedData, setClickedData] = useState();
    const [search, setSearch] = useState('')
    const [medRequestGroup, setMedRequestGroup] = useState([]);
    const [fromDate, setFromDate] = useState({});
    const [toDate, setToDate] = useState({})
    const [pacsResponse, setPacsResponse] = useState([])
    const [clickedRequestGroupId, setClickedRequestGroupId] = useState()
    const m = moment()
    const handleClickedReport = (data) => {
        setClickedData(data)
        handleOpen()
    }
    useEffect(() => {
        getMEDRequestGroup((isOk, data) => {
            if (!isOk) return alert(data.message);
            else return setMedRequestGroup(data)
        })
    }, [])
    useEffect(() => {
        if (clickedData) {

            setLoading(true)
            axios.post('https://api.gy1.ir/ghaffari/pacs', {
                "Level": "Study",
                "Query": { "PatientID": clickedData?.CodeReception },
                "RequestedTags": [
                    "ModalitiesInStudy"
                ],
                "Expand": true
            },
                {
                    headers: { "token": "Z2hhZmZhcmktYWRtaW4=" }
                })
                .then(function (response) {
                    setLoading(false);
                    setPacsResponse(response.data);
                })
                .catch(function (error) {
                    setLoading(false);
                    alert(error);
                });
        }
    }, [clickedData])

    useEffect(() => {
        if (dateFilter) {
            const currentDate = m.locale('fa').format('YYYY/MM/DD');
            if (dateFilter === "week")
                setFromDate({ date: moment(currentDate, 'jYYYY/jMM/jDD').locale('fa').subtract(7, 'd').format('jYYYY/jMM/jDD'), source: "click" });

            else if (dateFilter === "month")
                setFromDate({ date: moment(currentDate, 'jYYYY/jMM/jDD').locale('fa').subtract(1, 'month').format('jYYYY/jMM/jDD'), source: "click" });

            else if (dateFilter === "year")
                setFromDate({ date: moment(currentDate, 'jYYYY/jMM/jDD').locale('fa').subtract(1, 'year').format('jYYYY/jMM/jDD'), source: "click" });

            setToDate({ date: currentDate, source: "click" });

        }
    }, [dateFilter])

    const columns = [
        { field: 'DateReception', headerName: 'تاریخ', width: 100 },
        {
            field: 'reportStatus',
            headerName: 'گزارش/تصویر',
            width: 130,
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                if (!params.row.TextPlainReport || params.row.TextPlainReport === '')
                    return <p></p>

                else return <img src='/assets/images/medical-history.png' className='cursor-pointer' onClick={() => handleClickedReport(params.row)} alt='medical-history' />
            }
        },
        { field: 'CodeReception', headerName: 'کد پذیرش', width: 130 },
        {
            field: 'fullName',
            headerName: 'نام و نام خانوادکی',
            sortable: false,
            width: 150,
        },
        {
            field: 'CodeMelli',
            headerName: 'کدملی',
            sortable: false,
            width: 130
        },
        {
            field: 'NameGroups',
            headerName: 'گروه',
            sortable: false,
            width: 120
        },
        {
            field: 'NameFarsi',
            headerName: 'درخواست',
            sortable: false,
            width: 250
        }
    ];
    const mobileColumns = [
        {
            field: 'reportStatus',
            headerName: 'گزارش',
            width: 70,
            align: 'center',
            renderCell: (params) => {
                if (!params.row.TextPlainReport || params.row.TextPlainReport === '')
                    return <p></p>

                else return <img src='/assets/images/medical-history.png' className='cursor-pointer' onClick={() => handleClickedReport(params.row)} alt='medical-history' />
            }
        },
        { field: 'DateReception', headerName: 'تاریخ', width: 90 },
        {
            field: 'fullName',
            headerName: 'نام و نام خانوادکی',
            sortable: false,
            width: 120,
        }
    ];
    const DatePickerCustom = ({ openCalendar, value, handleValueChange, placeholder }) => {
        return (
            <input onFocus={openCalendar}
                value={value}
                onChange={handleValueChange}
                placeholder={placeholder}
                className='w-full sm:w-[200px] h-[56px] border pr-2 bg-[#f8f9fb] lg:ml-4 rounded'
                style={{ borderColor: 'rgba(0, 0, 0, 0.23)' }}
            />
        )
    }

    return (
        <div style={{ width: '100%' }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <RTLInput>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-1 sm:w-full w-[90%] mx-auto sm:place-items-center sm:justify-items-center lg:flex flex-wrap'>
                    <TextField label="نام بیمار" className='w-full sm:w-[200px] lg:ml-4' value={search} onChange={(event) => setSearch(event.target.value)} variant="outlined" />
                    <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        render={<DatePickerCustom placeholder="از تاریخ" />}
                        calendarPosition="bottom-right"
                        value={fromDate.date}
                        format="YYYY/MM/DD"
                        onChange={(date) => setFromDate({ date: date, source: "input" })}
                        className='w-full'
                    />
                    <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        render={<DatePickerCustom placeholder="تا تاریخ" />}
                        calendarPosition="bottom-right"
                        value={toDate.date}
                        format="YYYY/MM/DD"
                        onChange={(date) => setToDate({ date: date, source: "input" })}
                    />
                    <FormControl className='w-full sm:w-[200px]'>
                        <InputLabel>گروه ها</InputLabel>
                        <Select
                            value={clickedRequestGroupId}
                            label="گروه ها"
                            onChange={(event => setClickedRequestGroupId(event.target.value))}
                        >
                            <MenuItem value={''}>نمایش همه</MenuItem>
                            {medRequestGroup.map(request => <MenuItem key={request.idRequestsGroups} value={request.idRequestsGroups}>{request.NameGroups}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>

                <div className='hidden sm:block' style={{ height: 'calc(100vh - 120px)', width: '100%', marginTop: '1rem' }}>
                    <DataGrid
                        rows={data.filter(item => search !== '' ? item.fullName.match(new RegExp(`.*${search}.*`, "i")) : (fromDate.date && toDate.date && clickedRequestGroupId) ? item.DateReception >= (fromDate?.source === "input" ? fromDate?.date?.convert(persian, persian_en).format() : fromDate?.date) && item.DateReception <= (toDate?.source === "input" ? toDate?.date?.convert(persian, persian_en).format() : toDate?.date) && item.idRequestsGroups === clickedRequestGroupId : clickedRequestGroupId ? item.idRequestsGroups === clickedRequestGroupId : (fromDate.date && toDate.date) ? item.DateReception >= (fromDate?.source === "input" ? fromDate?.date?.convert(persian, persian_en).format() : fromDate?.date) && item.DateReception <= (toDate?.source === "input" ? toDate?.date?.convert(persian, persian_en).format() : toDate?.date) : true)}
                        columns={columns}
                        pageSize={20}
                        getRowId={(row) => row.idReceptionReport}
                        rowsPerPageOptions={[20]}
                        disableMultipleSelection
                    />
                </div>
                <div className='block sm:hidden mx-auto' style={{ height: 'calc(100vh - 120px)', width: '100%', marginTop: '1rem' }}>
                    <DataGrid
                        rows={data.filter(item => search !== '' ? item.fullName.match(new RegExp(`.*${search}.*`, "i")) : (fromDate.date && toDate.date && clickedRequestGroupId) ? item.DateReception >= (fromDate?.source === "input" ? fromDate?.date?.convert(persian, persian_en).format() : fromDate?.date) && item.DateReception <= (toDate?.source === "input" ? toDate?.date?.convert(persian, persian_en).format() : toDate?.date) && item.idRequestsGroups === clickedRequestGroupId : clickedRequestGroupId ? item.idRequestsGroups === clickedRequestGroupId : (fromDate.date && toDate.date) ? item.DateReception >= (fromDate?.source === "input" ? fromDate?.date?.convert(persian, persian_en).format() : fromDate?.date) && item.DateReception <= (toDate?.source === "input" ? toDate?.date?.convert(persian, persian_en).format() : toDate?.date) : true)}
                        columns={mobileColumns}
                        pageSize={10}
                        getRowId={(row) => row.idReceptionReport}
                        rowsPerPageOptions={[10]}
                        disableMultipleSelection
                        style={{width:"100%"}}
                    />
                </div>
                <p className='text-center text-[#898fa7f5] text-sm pt-2'>ساخته شده با ❤️ در <a href='https://gerafi.com' target='_blank' rel="noopener noreferrer" className='text-[#3ecddb] font-bold'>گرافی دات کام</a></p>
            </RTLInput>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style} className='w-full md:w-[750px]'>
                    <div className='flex justify-between'>
                        <section className='flex'>
                            <a href={`http://2.180.27.231:91/patreport.aspx?code=${clickedData?.CodeReception}`} target='_blank' rel="noreferrer">
                                <img src='/assets/images/word.png' alt='report' className='cursor-pointer w-14' />
                            </a>
                            <section onClick={() => pacsResponse.length > 0 && window.open(`http://2.180.20.24:8042/osimis-viewer/app/index.html?study=${pacsResponse[0]?.ID}&token=Z2hhZmZhcmk=`, "_blank")}>
                                <img src='/assets/images/pic.png' alt='pacs' className={`cursor-pointer w-14 mr-4 ${pacsResponse.length === 0 ? "grayscale opacity-50" : "grayscale-0 opacity-100"}`} />
                            </section>
                        </section>
                        <section className='flex text-sm items-end flex-col'>
                            <p className='mt-3'>تاریخ : {clickedData?.DateReception}</p>
                            <p className='my-1'>کد پذیرش : {clickedData?.CodeReception}</p>
                            <p>نام بیمار : {clickedData?.fullName}</p>
                        </section>
                    </div>
                    <p className='font-bold'>
                        حضور ارجمند دکتر {clickedData?.NameDoctorPatient}
                    </p>
                    <p className='mt-4'>{clickedData?.TextPlainReport}</p>
                </Box>
            </Modal>
        </div>
    )
}
