import React, { useState, useEffect } from 'react'
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import { getServices } from './../api/routes';
import { Link } from 'react-router-dom';

export default function Services() {
    const [services, setServices] = useState([]);

    useEffect(() => {
        getServices((isOk, data) => {
            if (!isOk) return alert(data.message);
            else return setServices(data)
        })
    }, [])
    return (
        <div className='container pt-24 pb-20' id='services'>
            <section className='flex flex-col items-center'>
                <p className='text-lg sm:text-2xl text-[#25406e] font-bold'>خدمات کلینیک</p>
                <div className='w-12 h-1 mt-4 bg-[#3ecddb]' />
            </section>
            <p className='text-center text-sm sm:text-base mt-8 mb-10'>خدماتی که برای سلامتی شما در این مرکز ارائه می شود</p>

            <div>
                <Flickity className='w-[95%] md:w-[85%] mx-auto' options={{ prevNextButtons: false, groupCells: 1, rightToLeft: true, initialIndex: 0, contain: true }}>
                    {services.map((item) =>
                        <section key={item.serviceId} className="object-cover w-[360px] h-[444px] rounded-[10px] flex flex-col ml-4">
                            {item.image && <img loading='lazy' src={`data:image/jpeg;base64,${item.image}`} alt={item.serviceTitle} className='w-full h-[202px] ml-4 object-cover rounded-[10px]' />}
                            <section className='ml-4'>
                                <p className='text-base sm:text-xl mt-5'>{item.serviceTitle}</p>
                                <p className='text-sm sm:text-base text-[#707070] mt-4 h-[100px]'>{item.serviceShortText}</p>
                            </section>
                            <Link to={`/service/${item.serviceId}`}>
                                <button className='bg-[#3ecddb] shadow-md hover:bg-[#fa3668] transition rounded-lg w-[123px] h-[45px] text-white text-sm font-bold mt-4'>ادامه مطلب</button>
                            </Link>
                        </section>)}
                </Flickity>
            </div>
        </div>
    )
}
