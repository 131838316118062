import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateTab } from '../../features/tab/tabSlice';
import { getRoles } from '../../api/routes';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress } from '@mui/material';

const Rules = () => {
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([])
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        handleOpen()
        getRoles((isOk, data) => {
            if (!isOk) {
                handleClose()
                return toast.error(data.message);
            }
            else {
                if (!data[0]?.StartLaw)
                    dispatch(updateTab(1))
                else {
                    handleClose()
                    return setRoles(data);
                }
            }
        })
    }, [])
    return (
        <div>
            <p className='text-[#0c96f8] text-center font-medium'>قوانین و مقررات</p>
            <section className='text-[#83878a] mt-4 sm:mt-8 text-[13px] [&>*]:mb-4'>
                {(roles.length > 0 && roles[0]?.StartLaw) ? parse(roles[0]?.StartLaw) : 'هنوز متنی وارد نشده است!'}
            </section>

            <button type="button" onClick={() => dispatch(updateTab(1))} className={`focus:outline-none text-white bg-[#2ecd71] hover:bg-green-500 focus:ring-green-300 font-medium rounded-lg w-[120px] text-sm px-5 py-2.5 float-left`}>مرحله بعد</button>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
export default Rules;