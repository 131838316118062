import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useEffect } from 'react';
import { useState } from 'react';
import { getFrequencyQuestions } from './../api/routes';

export default function FrequencyQuestions() {
    const [expanded, setExpanded] = useState(false);
    const [FQs, setFQs] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        getFrequencyQuestions((isOk, data) => {
            if (!isOk) return alert(data.message);
            else return setFQs(data)
        })
    }, [])

    return (
        <div className='bg-white'>
            <div className='container pt-12 py-20'>
                <div className='w-[95%] sm:w-[85%] mx-auto'>
                    <p className='text-lg sm:text-2xl text-[#25406e] font-bold mb-2'>سوالات متداول</p>
                    <div className='w-12 h-1 mt-4 bg-[#3ecddb]' />
                    <div className='flex justify-around flex-wrap'>
                        <section className='w-full lg:w-1/2 2xl:w-[700px] md:ml-4'>
                            {FQs.map((fq, index) =>
                                <Accordion key={fq.frequencyQuestionsId} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} sx={{ background: '#F6F7FA', minHeight: '60px', marginTop: '1.5rem', boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)', '& .MuiButtonBase-root': { minHeight: '60px' } }}>
                                    <AccordionSummary
                                        expandIcon={<img src='/assets/images/arrow-down.png' alt='arrow' />}
                                    >
                                        <p className='text-sm sm:text-base'>{fq.question}</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p className='text-sm sm:text-base'>
                                            {fq.answer}
                                        </p>
                                    </AccordionDetails>
                                </Accordion>)}
                        </section>
                        <img loading='lazy' src='/assets/images/faq.png' alt='doctor' className='w-full lg:w-[45%] object-contain 2xl:w-[570px] 2xl:h-[420px] mt-3' />
                    </div>
                </div>
            </div>
        </div>
    )
}
