import axios from "axios"

export const getAxiosInstanceApi = () => {
	return axios.create({
		baseURL: 'https://api.gy1.ir',
		headers:{
			'x-center-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjZW50ZXJJZCI6IjE3Iiwicm9sZSI6InVzZXIiLCJpYXQiOjE2OTMxNjY0NDB9.dnVdSv__3tgnAb2T5QiSaMWGqkLwhY9ut7T6LbuvVhA',
		}
	})
}
export const getReportInstanceApi = () => {
	return axios.create({
		baseURL: 'https://report.gy1.ir',
		headers:{
			'x-center-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjZW50ZXJJZCI6IjE3Iiwicm9sZSI6InVzZXIiLCJpYXQiOjE2OTMxNjY0NDB9.dnVdSv__3tgnAb2T5QiSaMWGqkLwhY9ut7T6LbuvVhA',
		}
	})
}
