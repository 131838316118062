import React from 'react'
import parse from 'html-react-parser';

export default function AboutUs({ aboutText, aboutImage }) {
    return (
        <div style={{ background: 'url(/assets/images/aboutusbg.svg)  7.5% 94.99% / cover no-repeat' }} >

            <div className='container flex px-6 sm:px-4 lg:px-0 justify-around pt-40 flex-wrap' id='about'>
                <section>
                    <p className='text-lg sm:text-2xl text-[#25406e] font-bold'>درباره پزشکی هسته ای صدرا</p>
                    <div className='w-12 h-1 mt-4 bg-[#3ecddb]' />
                    <div className='text-sm sm:text-base mt-4 lg:w-[400px] xl:w-[600px] text-justify'>{aboutText ? parse(aboutText) : ''}</div>

                </section>
                {aboutImage && <img loading='lazy' src={`data:image/jpeg;base64,${aboutImage}`} alt='doctor' className='md:h-[580px] mt-8 lg:mt-0 w-[90%] mx-auto md:mx-0 md:w-[450px] object-cover' />}
            </div>
        </div>
    )
}
