import React from 'react'
import { useEffect } from 'react'

export default function Map() {
    useEffect(() => {
        window.location.replace('https://www.google.com/maps/dir//%DA%A9%D9%84%DB%8C%D9%86%DB%8C%DA%A9+%D8%B3%D9%88%D9%86%D9%88%DA%AF%D8%B1%D8%A7%D9%81%DB%8C+%D9%88+%D8%B1%D8%A7%D8%AF%DB%8C%D9%88%D9%84%D9%88%DA%98%DB%8C+%D8%AF%DA%A9%D8%AA%D8%B1+%D8%BA%D9%81%D8%A7%D8%B1%DB%8C%D8%8C+Razavi+Khorasan+Province,+Mashhad,+Ferdowsi+Blvd,+8HF3%2BWJC,+Iran%E2%80%AD/@36.3245752,59.5543988,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3f6c934a1e300093:0x4245bc2cc8de4292!2m2!1d59.5540088!2d36.3247913')
    }, [])

    return (
        <div>redirect ...</div>
    )
}
