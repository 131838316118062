import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
    palette: {
        primary: {
            main: '#3ecddb',
        }
    },
    typography: {
        "fontFamily": "IRANSansX"
    }
})
export default Theme