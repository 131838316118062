import { Modal, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs';
import { FaWpforms } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { addContactUs } from '../api/routes';
import RTLInput from './RTLInput';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: 'fit-content' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'fit-content',
    borderRadius: '5px'
};
export default function FloatingBtn() {
    const [open, setOpen] = useState(false);
    const [openContactUs, setOpenContactUs] = useState(false);
    const handleCloseContactUs = () => setOpenContactUs(false);
    const handleOpenContactUs = () => setOpenContactUs(true);
    const [text, setText] = useState('');
    const [phoneNumberOrEmail, setPhoneNumberOrEmail] = useState('')
    const [fullname, setFullname] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    const persianTextRegex = /^[. 0 1 2 3 4 5 6 7 8 9 ، آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ\s]+$/

    const textChange = (event) => {
        if (event.target.value === '')
            return setText(event.target.value)
        else if (event.target.value.match(persianTextRegex)) {
            return setText(event.target.value)
        } else return alert('متن پیام باید فارسی باشد!')
    }

    const fullnameChange = (event) => {
        if (event.target.value === '')
            return setFullname(event.target.value)
        else if (event.target.value.match(persianTextRegex)) {
            return setFullname(event.target.value)
        } else return alert('نام و نام خانوادگی باید فارسی باشد!')
    }

    const newContactUs = () => {
        if (text !== '' && fullname !== '') {
            const data = {
                text,
                fullname,
                phoneNumberOrEmail
            }
            addContactUs(data, (isOk, data) => {
                if (!isOk) return alert(data.message);

                else {
                    handleCloseContactUs()
                    setFullname('')
                    setText('')
                    setPhoneNumberOrEmail('')
                    return toast.success('پیام شما ارسال شد!')
                }
            })
        } else return toast.error('تمام مقادیر را پرکنید!')
    }

    return (
        <>
            <SpeedDial
                ariaLabel="SpeedDial example"
                icon={<SpeedDialIcon sx={{ color: '#fff' }} />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="up"
                className='fixed right-[30px] bottom-[30px] z-[1000]'
            >
                <SpeedDialAction
                    icon={<a href='https://www.google.com/maps/dir//36.3247584,59.5617387/@36.3245892,59.5606008,18.01z?entry=ttu' target='_blank' rel="noopener noreferrer">
                        <MdLocationOn size='18px' /></a>}
                    tooltipTitle={'مسیردهی'}
                    sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: '90px', fontSize: '14px' } }}
                    tooltipOpen

                />
                <SpeedDialAction
                    icon={<FaWpforms size='18px' />}
                    tooltipTitle={'ارسال پیام'}
                    tooltipOpen
                    sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: '90px', fontSize: '14px' } }}
                    onClick={handleOpenContactUs}
                />
                <SpeedDialAction
                    icon={<a href='tel:05137647000'><BsTelephone size='18px' /></a>}
                    tooltipTitle={'پذیرش'}
                    tooltipOpen
                    sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: '90px', fontSize: '14px' } }}
                />
                <SpeedDialAction
                    icon={<AiOutlineArrowUp size='18px' />}
                    tooltipTitle={'برو بالا'}
                    tooltipOpen
                    onClick={topFunction}
                    sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: '90px', fontSize: '14px' } }}
                />
            </SpeedDial>
            <Modal open={openContactUs} onClose={handleCloseContactUs}>
                <Box sx={style}>
                    <RTLInput>
                        <section className='flex flex-col'>
                            <TextField color="primary" value={fullname} label="نام و نام خانوادگی" onChange={fullnameChange} InputProps={{ disableUnderline: true }} variant="filled" className='w-full sm:w-[400px] rounded-[10px] mb-6 text-sm' />
                            <TextField color="primary" value={phoneNumberOrEmail} onChange={(event) => setPhoneNumberOrEmail(event.target.value)} label="شماره تماس یا ایمیل" InputProps={{ disableUnderline: true }} variant="filled" className='w-full sm:w-[400px] rounded-[10px] mb-6 text-sm ltr-input' />
                            <TextField color="primary" value={text} multiline rows={5} label="متن" onChange={textChange} InputProps={{ disableUnderline: true }} variant="filled" className='w-full sm:w-[400px] rounded-[10px] text-sm' />
                            <button onClick={newContactUs} className='bg-[#3ecddb] button-shadow hover:bg-[#fa3668] transition rounded-lg w-full h-[45px] text-white text-sm font-bold mt-8'>ارسال</button>
                        </section>
                    </RTLInput>
                </Box>
            </Modal>
        </>
    )
}
