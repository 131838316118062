import { Backdrop, CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { updateTab } from "../../features/tab/tabSlice";
import { useDispatch } from 'react-redux';
import { Calendar, DateObject } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import persian_en from "react-date-object/locales/persian_en"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import { getSubRequestGroup, getReservedDays, getReservedTimes, getReservedSubTimes, addReservedSubTimes, getRequestGroup } from '../../api/routes';
import moment from 'jalali-moment'
import { toast } from 'react-toastify';
import setToSession from "./setToSession";
import getMinutes from './getMinutes';
import plusTimes from "./plusTimes";

const PickDate = () => {
    const m = moment();

    const [selectedDay, setSelectedDay] = useState(new DateObject());
    const [requestGroupData, setRequestGroupData] = useState([]);
    const [subRequestGroupData, setSubRequestGroupData] = useState([]);
    const [reservedDays, setReservedDays] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentTime, setCurrentTime] = useState();
    const [service, setService] = useState('');
    const [subService, setSubService] = useState('');
    const [openSubService, setOpenSubService] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(m?.locale('fa')?.format('MM'));
    const [selectedYear, setSelectedYear] = useState(m?.locale('fa')?.format('YYYY'));
    const [reservedTimes, setReservedTimes] = useState([])
    const [reservedSubTimes, setReservedSubTimes] = useState([])
    const [clickedSubTime, setClickedSubTime] = useState('')
    const [onChanging, setOnChanging] = useState(false);
    const dispatch = useDispatch();
    const [hideService, setHideService] = useState(false);

    let subTimes = reservedSubTimes.map((item) => item.subTime)

    const handleChange = (event) => {
        setService(event.target.value);
        setOnChanging(true)
        setClickedSubTime('')
        setLoading(true)
        getSubRequestGroup(event.target.value, (isOk, data) => {
            if (!isOk) return toast.error(data.message);
            else {
                setLoading(false)
                setOpenSubService(true);
                setSubRequestGroupData(data);
            }
        })

    };
    const handleChange1 = (event) => {
        setSubService(event.target.value);
        setOnChanging(true)
        setClickedSubTime('')
    };

    useEffect(() => {
        setLoading(true)
        getRequestGroup((isOk, data) => {
            if (!isOk) return toast.error(data.message);
            else {
                if (data.length === 1) {
                    setHideService(true)
                    setService(data[0].requestGroupId);
                    getSubRequestGroup(data[0].requestGroupId, (isOk, data) => {
                        if (!isOk) return toast.error(data.message);
                        else {
                            setLoading(false)
                            setSubRequestGroupData(data);
                        }
                    })
                } else {
                    setLoading(false)
                    return setRequestGroupData(data);
                }
            }
        })
    }, []);

    useEffect(() => {
        const selectDay = selectedDay.convert(persian, persian_en).format().split('/').join('');
        if (service && subService && selectDay) {
            setLoading(true)
            getReservedTimes(service, subService.subRequestGroupId, selectDay, (isOk, data) => {
                if (!isOk) return toast.error(data.message);
                else {
                    setReservedTimes(data);
                    getReservedSubTimes(data[0].requestGroupId, data[0].reservationDate, data[0].subRequestGroupId, (isOk, data) => {
                        if (!isOk) return toast.error(data.message);
                        else {
                            setLoading(false)
                            setOnChanging(false)
                            setClickedSubTime('')
                            setReservedSubTimes(data);
                        }
                    })
                }
            })
            setCurrentTime(undefined);
        }
    }, [selectedDay])

    useEffect(() => {
        setLoading(true)
        if (selectedMonth && selectedYear && service && subService) {
            getReservedDays(selectedMonth, selectedYear, service, subService.subRequestGroupId, (isOk, data) => {
                if (!isOk) return toast.error(data.message);
                else {
                    setLoading(false)
                    return setReservedDays(data);
                }
            })
        }
    }, [selectedMonth, selectedYear, service, subService])

    let activeTimes = []
    reservedTimes.map((item) => {
        activeTimes.push(item.startTime.slice(0, 2))
        if (item.startTime.slice(0, 2) !== item.endTime.slice(0, 2)) {
            return activeTimes.push(item.endTime.slice(0, 2))
        }
    })

    const disabledSubTimes = [];

    reservedTimes?.map((item) => {
        const divideHours = Math.floor(getMinutes(item.startTime, item.endTime) / item.patientNumber);
        for (let j = 0; j < item.patientNumber; j += 1) {
            disabledSubTimes.push({ fullNameGroup: item.fullNameGroup, endReservationTime: item.endReservationTime, reservationDate: item.reservationDate, requestGroupId: item.requestGroupId, subRequestGroupId: item.subRequestGroupId, reservationDayId: item.reservationDayId, time: moment(item.startTime, 'HH:mm').add(divideHours * j, 'minutes').format('HH:mm') });
        }
    })

    const nextStep = () => {
        const data = {
            reservationDayId: clickedSubTime.reservationDayId,
            requestGroupId: clickedSubTime.requestGroupId,
            subRequestGroupId: clickedSubTime.subRequestGroupId,
            subTime: clickedSubTime.time,
            reservationDate: clickedSubTime.reservationDate,
            endReservationTime: clickedSubTime.endReservationTime
        }
        addReservedSubTimes(data, (isOk, data) => {
            if (!isOk) return toast.error(data.message);
            else if (data.length > 0) {
                setToSession("subRequestGroupId", clickedSubTime.subRequestGroupId, 30);
                setToSession("requestGroupId", clickedSubTime.requestGroupId, 30);
                setToSession("price", subService.price, 30);
                setToSession("reservedSubTimesId", data[0].reservedSubTimesId, 30);
                setToSession("service", clickedSubTime.fullNameGroup, 30);
                setToSession("time", clickedSubTime.time || '', 30);
                setToSession("date", selectedDay.convert(persian, persian_en).format(), 30);
                dispatch(updateTab(2));
            }
        })
    }

    return (
        <>
            <div className="flex flex-wrap md:justify-around justify-center items-center">
                <section className="flex-1 md:flex-none">
                    <section className="flex flex-wrap flex-col w-full items-center justify-between mb-4">
                        {!hideService && <FormControl className="w-full md:w-[256px]">
                            <Select
                                value={service}
                                onChange={handleChange}
                                displayEmpty
                                style={{ fontFamily: 'IRANSansX' }}
                            >
                                <MenuItem value="" style={{ fontFamily: 'IRANSansX' }}>
                                    <p>انتخاب درخواست</p>
                                </MenuItem>
                                {requestGroupData.map((item) => (
                                    <MenuItem key={item.requestGroupId} value={item.requestGroupId} style={{ fontFamily: 'IRANSansX' }}>{item.nameGroup}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>}
                        <FormControl className="w-full md:w-[256px]">
                            <Select
                                value={subService}
                                onChange={handleChange1}
                                onClick={() => setOpenSubService(!openSubService)}
                                displayEmpty
                                renderValue={(value) => value ? value.subNameGroup : 'انتخاب خدمت'}
                                open={openSubService}
                                disabled={!service}
                                style={{ fontFamily: 'IRANSansX', marginTop: '0.5rem' }}
                            >
                                <MenuItem value="" style={{ fontFamily: 'IRANSansX' }}>
                                    <p>انتخاب خدمت</p>
                                </MenuItem>
                                {subRequestGroupData.map((item) => (
                                    <MenuItem key={item.subRequestGroupId} value={item} style={{ fontFamily: 'IRANSansX' }}>{item.subNameGroup}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </section>
                    <section className="flex-1 md:flex-none flex justify-center">
                        <Calendar
                            plugins={[weekends()]}
                            mapDays={({ date }) => {
                                let props = {}
                                if (reservedDays) {
                                    const q = reservedDays.map((item) => Number(String(item.reservationDate).slice(6)))
                                    let isWeekend = q.includes(date.day)
                                    if (!isWeekend) props.disabled = true
                                }
                                return props
                            }}
                            calendar={persian}
                            locale={persian_fa}
                            value={selectedDay}
                            format="YYYY/MM/DD"
                            minDate={m?.locale('fa')?.format('YYYY/MM/DD')}
                            onChange={(date) => setSelectedDay(date)}
                            disableYearPicker
                            onMonthChange={(date) => { setSelectedMonth(date.month.number); setSelectedYear(date.year) }}
                        />
                    </section>
                </section>
                <section className="w-full md:w-[60%]">
                    <p className='text-red-500 text-xs font-bold mt-3 sm:mt-8'>* زمان نوبت دهی را با دقت انتخاب کنید پس از زدن دکمه ادامه امکان بازگشت وجود ندارد!</p>
                    <section className={`grid grid-cols-3 justify-items-center mt-8 md:mt-4 items-center h-[95%] `}>
                        {!onChanging && disabledSubTimes.map((time, index) =>
                            <div onClick={() => { if (!subTimes.includes(time.time)) { setClickedSubTime(time) } }} key={index} className={`${subTimes.includes(time.time) ? 'bg-gray-300 text-gray-400' : time.time === clickedSubTime.time ? 'bg-green-500 text-white' : 'border-green-600 border text-green-600'} w-[100px] md:w-[120px] h-[50px] text-normal sm:text-[17px] m-1 rounded-md`}>
                                <p className={`'font-bold' 'font-normal '} h-full w-full flex items-center justify-center`}>{time.time}</p>
                            </div>
                        )}
                    </section>
                    <div className='flex justify-center'>
                        {currentTime !== undefined && <button type="button" onClick={() => { setCurrentTime(undefined) }} className='focus:outline-none text-white w bg-blue-500 hover:bg-blue-600 focus:blue-green-300 mt-8 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5'>بازگشت به ساعت ها</button>}
                    </div>
                </section>
            </div>

            <section className='mt-10 sm:mt-6'>
                <button type="button" onClick={() => clickedSubTime && nextStep()} className='focus:outline-none text-white  bg-[#2ecd71] hover:bg-green-500 focus:ring-green-300 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 w-[120px] float-left'>ادامه</button>
            </section>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
export default PickDate;