import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './app/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Theme from './theme';
import { ThemeProvider } from '@mui/material';
import { ProSidebarProvider } from 'react-pro-sidebar';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(    <ProSidebarProvider><ThemeProvider theme={Theme}><Provider store={store}><App /></Provider></ThemeProvider></ProSidebarProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();